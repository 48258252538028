import { css } from '@emotion/react'
import { colors, font } from 'plume-ui'
import React from 'react'
import Link from 'next/link'

type Props = {
  href: string
  type?: 'fill' | 'outline'
  size?: 'large' | 'xLarge'
  color?: 'black' | 'white'
  external?: boolean
  children: React.ReactNode
  onClick?: () => void
}

const cssFillBlack = css`
  border-color: ${colors.black20};
  background-color: ${colors.black10};
  color: ${colors.textInvert};
  :hover {
    background-color: ${colors.black30};
    border-color: ${colors.black30};
    color: ${colors.textInvert};
  }
`

const cssFillWhite = css`
  border-color: ${colors.white00};
  background-color: ${colors.white00};
  color: ${colors.textStrong};
`

const cssOutlineBlack = css`
  border-color: ${colors.borderStrong};
  color: ${colors.textStrong};
  :hover {
    background-color: ${colors.black20};
    border-color: ${colors.black20};
    color: ${colors.textInvert};
  }
`

const cssOutlineWhite = css`
  border-color: ${colors.white00};
  color: ${colors.textInvert};
`

export const EnterpriseButton: React.FC<Props> = ({
  type = 'fill',
  size = 'large',
  color = 'black',
  external = false,
  ...props
}) => {
  return (
    <Link href={props.href} passHref>
      <a
        onClick={props.onClick && props.onClick}
        css={css`
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-height: ${size == 'large' ? '48px' : '56px'};
          padding-inline: ${size == 'large' ? '22px' : '32px'};
          font-size: ${size == 'large' ? '14px' : '16px'};
          font-weight: ${font.weight.bold};
          letter-spacing: 0.02em;
          line-height: 100%;
          text-align: center;
          border: 1px solid transparent;
          border-radius: ${size == 'large' ? '24px' : '56px'};
          ${type == 'fill' && color == 'black' && cssFillBlack}
          ${type == 'fill' && color == 'white' && cssFillWhite}
          ${type == 'outline' && color == 'black' && cssOutlineBlack}
          ${type == 'outline' && color == 'white' && cssOutlineWhite}
        `}
        {...props}
        target={external ? '_blank' : '_self'}
      />
    </Link>
  )
}
